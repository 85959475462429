<template>
  <div class="flex flex-col document bg-white width-full height-full">
    <div>
      <h1 class="font-bold text-2xl lg:text-5xl py-10">เอกสารประกอบการซื้อ</h1>
    </div>
    <div class="grid self-center pb-10">
      <img :src="image" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: [String],
      default:
        "https://firebasestorage.googleapis.com/v0/b/rajpraditauto.appspot.com/o/files%2Fdocument.JPG?alt=media&token=84a41efb-48a4-463b-a2c1-3de86bcac9dc",
    },
  },
};
</script>
